<template>
  <transition name="fade-bottom-2x">
    <div class="vx-col w-full sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-3/4 mb-base">
      <vs-card class="cardx">
        <div slot="header">
          <vs-alert color="primary" :title="company.name" active="true">
            {{ companyId }}
          </vs-alert>
        </div>
        <div>
          <div class="mollie-checkout__invoice__wrapper">
            <div class="mollie-checkout__invoice__wrapper__content">
              <div class="mollie-checkout__invoice__wrapper__content__form">
                <div class="mollie-checkout__invoice__wrapper__content__form__fields">
                  <div class="mollie-checkout__invoice__wrapper__content__form__flex">
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                      <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                        <datepicker
                          ref="invoiceBillDate"
                          v-model="invoice.invoiceBillDate"
                          :inline="false"
                          :name="$t('vue.invoiceBillDate')"
                          v-validate="'required'"
                          :disabledDates="{ to: new Date(Date.now() - 8640000) }"
                          format="yyyy-MM-dd"
                          :clear-button="true"
                          :monday-first="true"
                          wrapper-class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                          clear-button-icon="fa fa-times"
                          @opened="isOpenDatePicker = true"
                          @closed="isOpenDatePicker = false"
                        >
                        </datepicker>
                        <div
                          class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                          :class="{
                            'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': !!invoice.invoiceBillDate || isOpenDatePicker,
                            'mollie-checkout__invoice__wrapper__content__form__fields__field__label--over': isOpenDatePicker
                          }"
                          @click="$refs.invoiceBillDate.$el.focus()"
                        >
                          {{ $t('vue.invoiceBillDate') }} *
                        </div>
                      </div>
                      <span v-if="errors.first($t('vue.invoiceBillDate'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('vue.invoiceBillDate')) }}
                      </span>
                    </div>
                    <div class="ml-2 mollie-checkout__invoice__wrapper__content__form__fields__field">
                      <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                        <vx-tooltip :text="$t('vue.generateInvoiceAmountMessage')" class="inline-flex" position="top">
                          <vs-switch
                            color="success"
                            :disabled="!selectedPlan || (selectedPlan && selectedPlan.billing_type === 'month')"
                            v-model="GENERATE_NEXT_INVOICE_DATE_BY_PLAN"
                          >
                            <span slot="on">year</span>
                            <span slot="off">month</span>
                          </vs-switch>
                        </vx-tooltip>
                      </div>
                    </div>
                    <div class="ml-2 mollie-checkout__invoice__wrapper__content__form__fields__field" v-if="invoice.selectedPlanId">
                      <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                        <vx-tooltip :text="$t('vue.totalAmountExcludingTax')" class="inline-flex" position="top">
                          <vs-chip color="success">
                            <vs-avatar text="€" />
                            {{ selectedPlanPrice }}
                          </vs-chip>
                        </vx-tooltip>
                      </div>
                    </div>
                  </div>

                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field mt-5" style="max-width: 179px">
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                      <multiselect
                        ref="title"
                        v-model="invoice.title"
                        :options="titles"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :placeholder="''"
                        :selectLabel="''"
                        :selectedLabel="''"
                        :deselectLabel="''"
                        label="name"
                        track-by="name"
                        :name="$t('inputs.salutation')"
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__select mollie-checkout__invoice__wrapper__content__form__fields__field__select--small-min-height"
                        open-direction="bottom"
                        v-validate="'required'"
                        :data-vv-as="$t('inputs.salutation')"
                        :preserve-search="true"
                        :preselect-first="false"
                        @close="isOpenTitleSelect = false"
                        @open="isOpenTitleSelect = true"
                      >
                        <template slot="noResult">No results found</template>
                      </multiselect>

                      <div
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                        :class="{
                          'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': !!invoice.title || isOpenTitleSelect,
                          'mollie-checkout__invoice__wrapper__content__form__fields__field__label--over': isOpenTitleSelect
                        }"
                        @click="$refs.title.$el.focus()"
                      >
                        {{ $t('inputs.salutation') }} *
                      </div>
                    </div>

                    <span v-if="errors.first($t('inputs.salutation'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                      <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.salutation')) }}
                    </span>
                  </div>
                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                      <multiselect
                        ref="selectedPlan"
                        v-model="selectedPlan"
                        :options="plans"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :placeholder="''"
                        :selectLabel="''"
                        :selectedLabel="''"
                        :deselectLabel="''"
                        label="name"
                        track-by="id"
                        name="choose-plan"
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__select mollie-checkout__invoice__wrapper__content__form__fields__field__select--small-min-height"
                        open-direction="bottom"
                        v-validate="'required'"
                        data-vv-as="choose-plan"
                        :preserve-search="true"
                        :preselect-first="false"
                        @close="isOpenPlanSelect = false"
                        @open="isOpenPlanSelect = true"
                      >
                        <template slot="noResult">No results found</template>
                      </multiselect>

                      <div
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                        :class="{
                          'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': !!selectedPlan || isOpenPlanSelect,
                          'mollie-checkout__invoice__wrapper__content__form__fields__field__label--over': isOpenPlanSelect
                        }"
                        @click="$refs.title.$el.focus()"
                      >
                        {{ $t('vue.choosePlan') }} *
                      </div>
                      <span v-if="errors.first('choose-plan')" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first('choose-plan') }}
                      </span>
                    </div>
                  </div>
                  <div v-if="selectedPlan" class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                      <input
                        ref="customPlanPrice"
                        type="number"
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                        v-model="customPlanPrice"
                        :label-placeholder="$t('inputs.customPlanPrice')"
                      />
                      <div
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                        :class="{
                          'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': customPlanPrice && customPlanPrice.length > 0
                        }"
                        @click="$refs.customPlanPrice.focus()"
                      >
                        {{ $t('inputs.customPlanPrice') }}
                      </div>
                    </div>
                  </div>

                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                      <input
                        ref="academicTitle"
                        type="text"
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                        v-model="invoice.academicTitle"
                        :name="$t('inputs.academicTitle')"
                        :label-placeholder="$t('inputs.academicTitle')"
                      />

                      <div
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                        :class="{
                          'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved':
                            invoice.academicTitle && invoice.academicTitle.length > 0
                        }"
                        @click="$refs.academicTitle.focus()"
                      >
                        {{ $t('inputs.academicTitle') }}
                      </div>
                    </div>
                  </div>
                  <div class="mollie-checkout__invoice__wrapper__content__form__fullname">
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                      <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                        <input
                          ref="firstName"
                          type="text"
                          class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                          v-model="invoice.firstName"
                          v-validate="'required'"
                          :data-vv-as="$t('inputs.firstName')"
                          :name="$t('inputs.firstName')"
                          :label-placeholder="$t('inputs.firstName')"
                        />

                        <div
                          class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                          :class="{
                            'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': invoice.firstName && invoice.firstName.length > 0
                          }"
                          @click="$refs.firstName.focus()"
                        >
                          {{ $t('inputs.firstName') }} *
                        </div>
                      </div>

                      <span v-if="errors.first($t('inputs.firstName'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.firstName')) }}
                      </span>
                    </div>
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                      <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                        <input
                          ref="lastName"
                          type="text"
                          class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                          v-model="invoice.lastName"
                          v-validate="'required'"
                          :data-vv-as="$t('inputs.lastName')"
                          :name="$t('inputs.lastName')"
                          :label-placeholder="$t('inputs.lastName')"
                        />

                        <div
                          class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                          :class="{
                            'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': invoice.lastName && invoice.lastName.length > 0
                          }"
                          @click="$refs.lastName.focus()"
                        >
                          {{ $t('inputs.lastName') }} *
                        </div>
                      </div>

                      <span v-if="errors.first($t('inputs.lastName'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.lastName')) }}
                      </span>
                    </div>
                  </div>

                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                      <input
                        ref="company"
                        type="text"
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                        v-model="invoice.company"
                        v-validate="'required'"
                        :data-vv-as="$t('inputs.company')"
                        :name="$t('inputs.company')"
                        :label-placeholder="$t('inputs.company')"
                        @change="$validator.validate($t('inputs.vatNumber'))"
                      />

                      <div
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                        :class="{
                          'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': invoice.company && invoice.company.length > 0
                        }"
                        @click="$refs.company.focus()"
                      >
                        {{ $t('inputs.company') }} *
                      </div>
                    </div>

                    <span v-if="errors.first($t('inputs.company'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                      <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.company')) }}
                    </span>
                  </div>

                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                      <input
                        ref="vatNumber"
                        type="text"
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                        v-model="invoice.vatNumber"
                        :name="$t('inputs.vatNumber')"
                        :label-placeholder="$t('inputs.vatNumber')"
                        v-validate="'isvatvalid'"
                        data-vv-validate-on="change"
                      />

                      <span v-if="errors.first($t('inputs.vatNumber'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.vatNumber')) }}
                      </span>

                      <div
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                        :class="{
                          'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': invoice.vatNumber && invoice.vatNumber.length > 0
                        }"
                        @click="$refs.vatNumber.focus()"
                      >
                        {{ $t('inputs.vatNumber') }}
                      </div>
                    </div>
                  </div>

                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                      <input
                        ref="streetAddress"
                        type="text"
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                        v-model="invoice.streetAddress"
                        v-validate="'required'"
                        :data-vv-as="$t('inputs.streetAddress')"
                        :name="$t('inputs.streetAddress')"
                        :label-placeholder="$t('inputs.streetAddress')"
                      />

                      <div
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                        :class="{
                          'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved':
                            invoice.streetAddress && invoice.streetAddress.length > 0
                        }"
                        @click="$refs.streetAddress.focus()"
                      >
                        {{ $t('inputs.streetAddress') }} *
                      </div>
                    </div>

                    <span v-if="errors.first($t('inputs.streetAddress'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                      <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.streetAddress')) }}
                    </span>
                  </div>

                  <div class="mollie-checkout__invoice__wrapper__content__form__equal-parts">
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                      <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                        <input
                          ref="city"
                          type="text"
                          class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                          v-model="invoice.city"
                          v-validate="'required'"
                          :data-vv-as="$t('inputs.city')"
                          :name="$t('inputs.city')"
                          :label-placeholder="$t('inputs.city')"
                        />

                        <div
                          class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                          :class="{
                            'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': invoice.city && invoice.city.length > 0
                          }"
                          @click="$refs.city.focus()"
                        >
                          {{ $t('inputs.city') }} *
                        </div>
                      </div>

                      <span v-if="errors.first($t('inputs.city'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.city')) }}
                      </span>
                    </div>
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                      <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                        <input
                          ref="zipOrPostalCode"
                          type="text"
                          class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                          v-model="invoice.zipOrPostalCode"
                          v-validate="'required'"
                          :data-vv-as="$t('inputs.zipOrPostalCode')"
                          :name="$t('inputs.zipOrPostalCode')"
                          :label-placeholder="$t('inputs.zipOrPostalCode')"
                        />

                        <div
                          class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                          :class="{
                            'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved':
                              invoice.zipOrPostalCode && invoice.zipOrPostalCode.length > 0
                          }"
                          @click="$refs.zipOrPostalCode.focus()"
                        >
                          {{ $t('inputs.zipOrPostalCode') }} *
                        </div>
                      </div>

                      <span v-if="errors.first($t('inputs.zipOrPostalCode'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.zipOrPostalCode')) }}
                      </span>
                    </div>
                  </div>

                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                      <multiselect
                        ref="country"
                        v-model="invoice.country"
                        :options="countryCodes"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :placeholder="''"
                        :selectLabel="''"
                        :selectedLabel="''"
                        :deselectLabel="''"
                        label="countryName"
                        track-by="name"
                        :name="$t('inputs.country')"
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__select"
                        open-direction="bottom"
                        v-validate="'required'"
                        :data-vv-as="$t('inputs.country')"
                        :preserve-search="true"
                        :preselect-first="false"
                        @close="isOpenCountrySelect = false"
                        @open="isOpenCountrySelect = true"
                      >
                        <template slot="noResult">No results found</template>
                      </multiselect>

                      <div
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                        :class="{
                          'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': !!invoice.country || isOpenCountrySelect,
                          'mollie-checkout__invoice__wrapper__content__form__fields__field__label--over': isOpenCountrySelect
                        }"
                        @click="$refs.country.$el.focus()"
                      >
                        {{ $t('inputs.country') }} *
                      </div>
                    </div>

                    <span v-if="errors.first($t('inputs.country'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                      <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.country')) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-button type="gradient" class="mr-2" color="primary" icon="save" @click="saveInvoice"></vs-button>
            <vs-button color="primary" class="mr-2" icon="close" @click="$emit('close-invoice')"></vs-button>
          </vs-row>
        </div>
      </vs-card>
    </div>
  </transition>
</template>
<script>
import { paddle } from '@/mixins/paddle'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'
// VeeValidate
import { Validator } from 'vee-validate'
// Merge all defined languages in the dictionary and sets the current locale.
import validationDictionary from '@/validations/validationDictionary'
import Datepicker from 'vuejs-datepicker'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
const isBetween = require('dayjs/plugin/isBetween')
const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(customParseFormat)

export default {
  name: 'Invoice',
  components: {
    Multiselect,
    Datepicker
  },
  mixins: [paddle],
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
  data: (state) => ({
    GENERATE_NEXT_INVOICE_DATE_BY_PLAN: false,
    loaderSpinnerText: 'Processing...',
    isLoading: false,
    isLoadingInProccess: false,
    titles: [
      {
        id: 0,
        name: 'Herr'
      },
      {
        id: 1,
        name: 'Frau'
      }
    ],
    plans: [],
    selectedPlan: null,
    company: null,
    invoice: {
      currency: { text: 'EUR', symbol: '€' },
      title: '',
      academicTitle: '',
      firstName: '',
      lastName: '',
      company: '',
      companyType: '',
      vatNumber: '',
      email: '',
      streetAddress: '',
      city: '',
      zipOrPostalCode: '',
      country: '',
      selectedPlanId: ''
    },
    paymentType: 'invoice',
    isOpenCountrySelect: false,
    isOpenDatePicker: false,
    isOpenTitleSelect: false,
    isOpenPlanSelect: false,
    countryCodes: state.$i18n.locale === 'de' ? require('@/assets/countryCodes_de.json') : require('@/assets/countryCodes.json'),
    customPlanPrice: null,

    mode: null,
    mounted: false
  }),
  created() {
    /* Localize validator */
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])

    Validator.extend('isvatvalid', {
      validate: this.isVatValid,
      getMessage: (field, params, data) => data.message
    })
  },
  async mounted() {
    const plans = this.getBaseSubscriptionPlans()
    this.plans = plans
      .filter((x) => x.enabled)
      .map((y) => {
        return {
          id: y.id,
          name: y.description,
          billing_type: y.billing_type,
          recurring_price: y.recurring_price,
          lottery_price: y.lottery_price
        }
      })

    const companyDoc = await this.$db.collection('company').doc(this.companyId).get()
    this.company = companyDoc.data()

    let createdByUser = null
    if (this.company && this.company.createdBy) {
      const createdByUserDoc = await this.$db.collection('users').doc(this.company.createdBy).get()
      createdByUser = createdByUserDoc.data()
    }

    if (this.company.name) {
      this.invoice.company = this.company.name
      this.invoice.city = this.company.city

      this.invoice.country = this.company.country
      let streetAddress = this.company.street
      if (this.company.houseNumber) {
        streetAddress = `${streetAddress} ${this.company.houseNumber}`
      }
      this.invoice.streetAddress = streetAddress
      this.invoice.vatNumber = this.company.vatNumber
      this.invoice.zipOrPostalCode = this.company.zipcode
      this.invoice.firstName = createdByUser && createdByUser.firstname ? createdByUser.firstname : ''
      this.invoice.lastName = createdByUser && createdByUser.lastname ? createdByUser.lastname : ''
      this.invoice.email = createdByUser && createdByUser.email ? createdByUser.email : ''
    }

    if (!this.company.currentInvoiceId) {
      this.mode = 'new'
      this.invoice.isFirstInvoice = true
    } else {
      this.mode = 'edit'
      this.invoice.isFirstInvoice = false
      const invoiceDoc = await this.$db.collection('pathadvice-invoices').doc(this.company.currentInvoiceId).get()
      const invoice = invoiceDoc.data()
      if (invoice) {
        this.invoice.country = invoice.country
        this.invoice.title = invoice.title
        this.invoice.academicTitle = invoice.academicTitle
        this.invoice.firstName = invoice.firstName

        this.invoice.lastName = invoice.lastName
        this.invoice.company = invoice.company
        this.invoice.companyType = invoice.companyType
        this.invoice.vatNumber = invoice.vatNumber
        this.invoice.email = invoice.email
        this.invoice.streetAddress = invoice.streetAddress
        this.invoice.city = invoice.city
        this.invoice.zipOrPostalCode = invoice.zipOrPostalCode

        this.invoice.selectedPlanId = invoice.selectedPlanId
        this.invoice.status = invoice.status

        this.invoice.invoiceBillDate = dayjs.unix(invoice.nextPaymentDate.seconds).format('YYYY-MM-DD')
      }

      if (this.invoice.selectedPlanId) {
        this.selectedPlan = this.plans.find((x) => x.id === this.invoice.selectedPlanId)
      }
    }

    setTimeout(() => {
      this.mounted = true
    }, 100)
  },
  watch: {
    'invoice.country.code'() {
      if (this.mounted) {
        this.$validator.validate(this.$i18n.t('inputs.vatNumber'))
      }
    },

    'invoice.vatNumber'() {
      this.invoice.vatNumber = this.invoice.vatNumber.toUpperCase()
      this.checkIfVatHasCountryCode()
    },

    selectedPlan() {
      this.customPlanPrice = null
      if (this.selectedPlan) {
        this.invoice.selectedPlanId = this.selectedPlan.id
        if (this.selectedPlan.billing_type === 'month') {
          this.GENERATE_NEXT_INVOICE_DATE_BY_PLAN = false
        }
      }
    }
  },
  computed: {
    selectedPlanPrice() {
      if (this.customPlanPrice) {
        return this.customPlanPrice
      }
      if (this.selectedPlan) {
        if (this.selectedPlan && this.selectedPlan.billing_type === 'year' && this.GENERATE_NEXT_INVOICE_DATE_BY_PLAN) {
          const price = parseFloat(this.selectedPlan.recurring_price['EUR']) * 12
          return price.toFixed(2)
        }
        return this.selectedPlan.recurring_price['EUR']
      }
      return null
    }
  },
  methods: {
    hasNumber(code) {
      return /\d/.test(code)
    },
    customSpinnerLoad(enable, text) {
      if (enable) {
        this.loaderSpinnerText = text
        this.isLoading = enable
        this.isLoadingInProccess = enable
      } else {
        this.loaderSpinnerText = ''
        this.isLoading = enable
        this.isLoadingInProccess = enable
      }
    },
    isVatValid() {
      const vm = this
      return new Promise((resolve) => {
        vm.customSpinnerLoad(true, this.$i18n.t('vue.validatingVAT'))

        if (!vm.checkIfVatHasCountryCode()) {
          setTimeout(() => {
            vm.customSpinnerLoad(false)
          }, 100)
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_002')
            }
          })
        }

        if (!vm.invoice || !vm.invoice.company || (vm.invoice && vm.invoice.company && vm.invoice.company.trim().length === 0)) {
          setTimeout(() => {
            vm.customSpinnerLoad(false)
          }, 100)
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_004')
            }
          })
        }

        if (!vm.invoice || !vm.invoice.country || !vm.invoice.country.code) {
          setTimeout(() => {
            vm.customSpinnerLoad(false)
          }, 100)
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_001')
            }
          })
        }

        if (!vm.invoice || !vm.invoice.vatNumber || vm.invoice.vatNumber.length <= 2) {
          setTimeout(() => {
            vm.customSpinnerLoad(false)
          }, 100)
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_003')
            }
          })
        }

        const axios = require('axios')
        const vatNumber = vm.invoice.vatNumber.substring(2)
        const companyName = encodeURI(vm.invoice.company)
        axios
          .get(`${vm.$functionsURL}/validateVat?cc=${vm.invoice.country.code}&vat=${vatNumber}&cp=${companyName}`)
          .then(function (response) {
            if (response && response.data && response.data.success) {
              vm.customSpinnerLoad(false)
              return resolve({
                valid: true
              })
            }
            if (response && response.data && response.data.error) {
              vm.customSpinnerLoad(false)
              return resolve({
                valid: false,
                data: {
                  message: vm.$i18n.t(response.data.error)
                }
              })
            }
          })
          .catch(function (error) {
            vm.customSpinnerLoad(false)
            return resolve({
              valid: false,
              data: {
                message: error.message
              }
            })
          })
      })
    },
    checkIfVatHasCountryCode() {
      if (!this.invoice || !this.invoice.vatNumber || this.invoice.vatNumber.length < 2) return false
      const vat = this.invoice.vatNumber.toLowerCase()
      const countryCodeVat = vat.substr(0, 2)
      /* Return false if country code has digits */
      if (this.hasNumber(countryCodeVat)) return false

      const countryCode = this.countryCodes.find((x) => x.code === countryCodeVat)
      if (countryCode) {
        this.invoice.country = countryCode
      }

      if (!countryCode) {
        return false
      }

      return true
    },
    async saveInvoice(event) {
      this.$vs.loading()
      event.preventDefault()
      if (this.selectedPlan) {
        this.invoice.selectedPlanId = this.selectedPlan.id
      }

      const result = await this.$validator.validateAll()

      if (!result) {
        setTimeout(() => {
          this.$vs.loading.close()
        }, 100)
        return
      }

      const company = {
        currency: { text: 'EUR', symbol: '€' },
        GENERATE_NEXT_INVOICE_DATE_BY_PLAN: this.GENERATE_NEXT_INVOICE_DATE_BY_PLAN,
        city: this.invoice.city,
        name: this.invoice.company,
        country: this.invoice.country,
        countryCode: this.invoice.country.code,
        street: this.invoice.streetAddress,
        vatNumber: this.invoice.vatNumber,
        zipcode: this.invoice.zipOrPostalCode
      }
      await this.$db.collection('company').doc(this.companyId).set(company, { merge: true })

      this.invoice.companyId = this.companyId
      this.invoice.createdAt = this.$firebase.firestore.FieldValue.serverTimestamp()
      this.invoice.updatedAt = this.$firebase.firestore.FieldValue.serverTimestamp()
      this.invoice.emailLink = `${window.location.protocol}//${window.location.host}/payment?showInvoice=`

      if (this.invoice.invoiceBillDate) {
        this.invoice.invoiceBillDate = dayjs(this.invoice.invoiceBillDate).format('YYYY-MM-DD')
      }

      if (this.mode === 'new') {
        if (this.customPlanPrice) {
          this.invoice.planAmount = this.customPlanPrice
        }
        const invoiceRef = await this.$db.collection('pathadvice-invoices').add(this.invoice)
        if (invoiceRef && invoiceRef.id) {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.success'),
            text: 'Invoice Created',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
        }
        this.$emit('close-invoice', { type: 'created', currentInvoiceId: invoiceRef.id })
      }
      this.$vs.loading.close()
    }
  }
}
</script>

<style lang="scss">
.vdp-datepicker input {
  border: none;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 20px;
}
.vdp-datepicker .vdp-datepicker__calendar {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
.mollie-checkout__invoice__wrapper__content__form__fields__field__select {
  height: 60px;
  .multiselect__single {
    text-transform: capitalize;
  }

  .multiselect__option {
    text-transform: capitalize;
    span {
      text-transform: capitalize;
    }
    &.multiselect__option--highlight {
      color: #262629;
      background: #f1f9ff;
    }
  }

  .multiselect {
    &__tags {
      min-height: 60px;
    }

    &__select {
      height: 55px;
    }

    &__single,
    &__input {
      padding-top: 10px;
    }

    &__content-wrapper {
      min-height: 200px !important;
    }
  }

  &--small-min-height {
    .multiselect {
      &__content-wrapper {
        min-height: 80px !important;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@font-face {
  font-family: 'Larsseit';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Regular.woff');
}
@font-face {
  font-family: 'Larsseit-Medium';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Medium.woff');
}
@font-face {
  font-family: 'Larsseit-Bold';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Bold.woff');
}
</style>
<style lang="scss">
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.mollie-checkout {
  font-feature-settings: 'ss01' on;
  font-family: 'Larsseit-Regular';

  &_charging_info {
    padding: 30px;
    text-align: center;
    font-size: 1.1rem;
  }

  &__holder {
    margin-bottom: 32px;
    padding: 33px 20px 26px 20px;
    border: 1px solid #a3cde8;
    border-radius: 8px;
    min-height: 370px;
    height: 100%;

    &--modal {
      overflow: hidden auto;
      max-height: 450px;
    }
  }
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    z-index: 99;

    &__loader {
      text-align: center;

      &__spinner {
        display: inline-block;
        width: 70px;
        height: 70px;
        border: 10px solid rgb(222, 241, 254);
        border-bottom-color: rgb(66, 181, 255);
        border-radius: 50%;
        animation: rotation 1s linear infinite;
      }

      &__title {
        margin-top: 28px;
        color: rgb(38, 38, 41);
        font-family: 'Larsseit-Bold';
        font-size: 28px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.155556px;
      }

      &__text {
        margin-top: 6px;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.111111px;
        color: rgb(83, 83, 91);
      }
    }
  }
  &__invoice {
    &__wrapper {
      margin-bottom: 32px;
      &__content {
        &__form {
          &__flex {
            display: flex;
            flex: 1;
          }

          &__fullname {
            display: flex;
            .mollie-checkout__invoice__wrapper__content__form__fields__field {
              &:first-child {
                width: 100%;
                max-width: 179px;
                margin-right: 16px;
              }
              &:last-child {
                width: 100%;
                max-width: 248px;
              }
            }
            @media (max-width: 575px) {
              flex-direction: column;
              .mollie-checkout__invoice__wrapper__content__form__fields__field {
                &:first-child,
                &:last-child {
                  max-width: 100%;
                }
              }
            }
          }
          &__equal-parts {
            display: flex;
            justify-content: space-between;
            .mollie-checkout__invoice__wrapper__content__form__fields__field {
              width: 100%;
              max-width: calc((100% / 2) - 8px);
            }
            @media (max-width: 575px) {
              flex-direction: column;
              .mollie-checkout__invoice__wrapper__content__form__fields__field {
                max-width: 100%;
              }
            }
          }
          &__fields {
            &__field {
              margin-top: 28px;

              &__inner {
                position: relative;
              }

              &__label {
                pointer-events: none;
                position: absolute;
                top: 50%;
                left: 20px;
                padding: 0 2px;
                touch-action: none;
                color: #878894;
                font-size: 20px;
                line-height: 20px;
                letter-spacing: -0.111111px;
                background: #ffffff;
                transform: translateY(-50%);
                max-width: calc(100% - 50px);

                &--moved {
                  top: 0;
                  font-family: 'Larsseit-Bold';
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.0833333px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  transition: all 0.2s linear;
                }

                &--over {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  z-index: 98;
                }
              }

              &__input {
                padding: 5px 20px;
                width: 100%;
                height: 60px;
                color: #35495e;
                font-family: 'Larsseit-Regular';
                font-size: 16px;
                line-height: 18px;
                background: rgb(255, 255, 255);
                border: 1px solid rgb(217, 219, 221);
                border-radius: 8px;

                &:focus + .mollie-checkout__invoice__wrapper__content__form__fields__field__label {
                  top: 0;
                  font-family: 'Larsseit-Bold';
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.0833333px;
                  transition: all 0.2s linear;
                }
              }

              &__rules-wrapper {
                display: flex;
                flex-wrap: wrap;
                margin-top: 24px;
              }

              &__rule {
                display: inline-flex;
                align-items: center;
                margin-top: 5px;
                margin-right: 4px;
                padding: 4px 14px;
                color: #538fb5;
                font-family: 'Larsseit-Medium';
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.04375px;
                background: #e8f6ff;
                border-radius: 12px;

                &__icon {
                  margin-right: 5px;
                }

                &--valid {
                  color: rgb(65, 184, 131);
                  background: rgba(65, 184, 131, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
  &__checkout {
    margin-bottom: 13px;
    &__caption {
      color: rgb(83, 83, 91);
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.1px;
      text-align: center;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 28px;

      @media (max-width: 575px) {
        flex-wrap: wrap;
        margin-top: 18px;
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100% / 4 - 18px * 2 / 4);
      height: 60px;
      color: rgb(38, 38, 41);
      font-family: 'Larsseit-Bold';
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.0888889px;
      background: rgb(255, 255, 255);
      border: 1px solid rgb(217, 219, 221);
      box-shadow: 0px 2px 1px rgba(52, 53, 110, 0.0800422);
      border-radius: 8px;
      font-weight: bold;
      position: relative;
      overflow: hidden;
      @media (max-width: 575px) {
        margin-top: 10px;
        width: calc(50% - 5px);
      }
      &__wrapper {
        /* opacity: 0.5; */
        &.mollie-checkout__checkout__button__wrapper--not-active {
          opacity: 0.5;
        }
      }
      &__soon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        &__wrapper {
          background: rgba(130, 206, 255, 0.2);
          backdrop-filter: blur(1px);
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 1;
        }
        span {
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: #262629;
          position: relative;
          z-index: 2;
        }
      }
      &.mollie-checkout__checkout__button--active {
        border-color: #a3cde8;
        background: #f1f9ff;
      }

      &:hover {
        cursor: pointer;
        background: #f1f9ff;
      }
      &--paypal {
        img {
          width: 75px;
          height: 20px;
        }
      }
      &--sepa {
        img:first-child {
          margin-bottom: 2.5px;
        }
      }
    }
  }
  &__next-button {
    display: flex;
    align-items: center;
    color: white;
    font-family: 'Larsseit-Bold';
    font-size: 20px;
    line-height: 18px;
    letter-spacing: -0.111111px;
    padding: 20px 43px;
    background: #3034f7;
    border: none;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
      background: rgba(48, 52, 247, 0.9);
    }

    &-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 50px;
    }

    &__icon {
      margin-left: 17px;
      width: 20px;
    }
  }
}

.wrapper {
  display: flex;
}

.wrapper form {
  width: 100%;
}

.row {
  display: flex;
  width: 100%;
  margin: 1px 0;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

/* form group */
.form-group {
  width: 100%;
  position: relative;
  margin: 0 8px;
  flex: 1;
}
.form-group.form-group--expiry-date {
  width: 100%;
  max-width: 263px;
}
.form-group.form-group--verification-code {
  width: 100%;
  max-width: 158px;
}

.row .form-group:first-child {
  margin-left: 0;
}

.row .form-group:nth-last-child(1) {
  margin-right: 0;
}

.form-group label {
  position: absolute;
  left: 20px;
  top: 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #a3b2c6;
  height: 60px;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 5;
  div {
    display: inline-flex;
  }
}

input:not(:placeholder-shown) ~ label,
input:focus ~ label,
.form-group--expiry-date label,
.form-group.is-dirty label,
.form-group.is-focussed label {
  font-size: 12px;
  transform: translateY(-50%);
  div {
    background: white;
    border-radius: 8px;
    padding: 0 5px;
  }
}

.form-input {
  outline: none;
  background-color: transparent;
  font-size: 18px;
}

.form-input,
.mollie-component {
  background: #eff1f3;
  min-height: 60px;
  border-radius: 8px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.mollie-component::after {
  transition: 0.5s width cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #3a4;
  content: '';
  position: absolute;
  bottom: -1px;
  width: 0%;
  left: 0;
  border-bottom: 1px solid #ce0000;
}

.mollie-component.is-invalid::after {
  width: 100%;
}

.form-group.is-touched.is-valid .field-error {
  padding: 0;
}

.form-group.is-touched .field-error {
  color: #ce0000;
  font-weight: 400;
  padding: 1px 0;
  font-size: 10px;
}

button.submit-button {
  width: 100%;
  padding: 14px;
  color: #162941;
  font-weight: 600;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  outline: 0;
  transition: 0.3s opacity cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid #162941;
  background-color: transparent;
}

.form-fields {
  margin-bottom: 32px;
  padding: 33px 0 0 0;
  margin-top: -33px;
}
</style>
