<template>
  <transition name="fade-bottom-2x flex">
    <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base" v-show="showPopup">
      <div class="flex justify-align-center">
        <vx-card class="add-coupon">
          <div id="coupon-container">
            <div class="pt-2 pb-2 pr-2 pl-2" align="right">
              <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-x" @click="close()"></vs-button>
            </div>
            <div slot="header" class="custom-card__header">
              <h4>{{ $t('vue.completeYourRegistration') }}</h4>
            </div>
            <div class="vx-row mt-6 mb-6">
              <div class="vx-col">
                <span>{{ $t('inputs.firstName') }}</span>
              </div>
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  v-model="firstname"
                  v-validate="'required|min:3'"
                  :name="$t('inputs.firstName')"
                  :data-vv-as="$t('inputs.firstName')"
                  validate-on="blur"
                />
              </div>
              <div class="vx-col w-full error-message" v-if="formSubmitted && errors.first($t('inputs.firstName'))">
                * {{ errors.first($t('inputs.firstName')) }}
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <span class="w-full">{{ $t('inputs.lastName') }}</span>
              </div>
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  v-model="lastname"
                  v-validate="'required|min:3'"
                  :name="$t('inputs.lastName')"
                  :data-vv-as="$t('inputs.lastName')"
                  validate-on="blur"
                />
              </div>
              <div class="vx-col w-full error-message" v-if="formSubmitted && errors.first($t('inputs.lastName'))">
                * {{ errors.first($t('inputs.lastName')) }}
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <span class="w-full">{{ $t('inputs.email') }}</span>
              </div>
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  v-model="email"
                  v-validate="{
                    required: true,
                    email: true,
                    isEmailAlreadyTaken: true
                  }"
                  :name="$t('inputs.email')"
                  :data-vv-as="$t('inputs.email')"
                  validate-on="blur"
                />
              </div>
              <div class="vx-col w-full error-message" v-if="formSubmitted && errors.first($t('inputs.email'))">* {{ errors.first($t('inputs.email')) }}</div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <span class="w-full">{{ $t('inputs.companyName') }}</span>
              </div>
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  v-model="accountName"
                  v-validate="'required|min:3'"
                  :name="$t('inputs.companyName')"
                  :data-vv-as="$t('inputs.companyName')"
                  validate-on="blur"
                />
              </div>
              <div class="vx-col w-full error-message" v-if="formSubmitted && errors.first($t('inputs.companyName'))">
                * {{ errors.first($t('inputs.companyName')) }}
              </div>
            </div>

            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <span class="w-full">{{ $t('vue.expiryDate') }}</span>
              </div>
              <div class="vx-col w-full">
                <datepicker
                  v-model="expiryDate"
                  :name="$t('vue.expiryDate')"
                  format="MM/dd/yyyy"
                  :disabledDates="{ to: new Date(Date.now() - 8640000) }"
                  v-validate="'required'"
                  :inline="true"
                ></datepicker>
              </div>
              <div class="vx-col w-full error-message" v-if="formSubmitted && errors.first($t('vue.expiryDate'))">
                * {{ errors.first($t('vue.expiryDate')) }}
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col flex items-center justify-end w-full">
                <vs-button @click="createFreeTrialAccount" :disabled="isCreatingAccount || (!validateForm && formSubmitted)" class="mb-2">{{
                  $t('vue.createAccount')
                }}</vs-button>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </transition>
</template>
<script>
import 'firebase/functions'
import Datepicker from 'vuejs-datepicker'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

import { Validator } from 'vee-validate'
// Merge all defined languages in the dictionary and sets the current locale.
import validationDictionary from '@/validations/validationDictionary'

export default {
  components: {
    Datepicker
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    validateForm() {
      return !this.errors.any()
    }
  },
  data() {
    return {
      firstname: '',
      lastname: '',
      accountName: '',
      email: '',
      expiryDate: null,
      formSubmitted: false,
      isCreatingAccount: false
    }
  },
  created() {
    /* Localize validator */
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])
  },
  mounted() {
    Validator.extend('isEmailAlreadyTaken', {
      validate: this.isEmailAlreadyTaken,
      getMessage: (field, params, data) => data.message
    })
  },
  methods: {
    async isEmailAlreadyTaken(email) {
      return new Promise((resolve) => {
        const checkEmailExists = this.$functions.httpsCallable('checkEmailExists')
        checkEmailExists({
          email
        }).then((res) => {
          if (res && res.data) {
            return resolve({
              valid: false,
              data: {
                message: this.$i18n.t('error.C_107')
              }
            })
          }
          return resolve({
            valid: true
          })
        })
      })
    },

    convertToMMDDYYYY(dateString) {
      const dateObject = new Date(dateString)
      const month = dateObject.getMonth() + 1 // Months are zero based, so we add 1
      const day = dateObject.getDate()
      const year = dateObject.getFullYear()

      // Ensure leading zeros for month and day if needed
      const monthString = month < 10 ? `0${month}` : `${month}`
      const dayString = day < 10 ? `0${day}` : `${day}`

      return `${monthString}/${dayString}/${year}`
    },
    close(emit) {
      this.$nextTick(() => {
        // Reset validation errors and clear form fields
        this.errors.clear()
        this.$validator.reset()
      })

      this.firstname = ''
      this.lastname = ''
      this.accountName = ''
      this.email = ''
      this.expiryDate = null
      this.formSubmitted = false
      if (emit) {
        this.$serverBus.$emit(emit)
      } else {
        this.$serverBus.$emit('close-create-account')
      }
    },
    async createFreeTrialAccount() {
      if (this.isCreatingAccount) return
      this.isCreatingAccount = true

      this.formSubmitted = true
      await this.$validator.validate()
      if (!this.validateForm) {
        this.isCreatingAccount = false
        return
      }

      const _expiryDate = this.convertToMMDDYYYY(this.expiryDate)

      const customer = {
        firstname: this.firstname,
        lastname: this.lastname,
        displayName: `${this.firstname} ${this.lastname}`,
        accountName: this.accountName,
        email: this.email,
        expiryDate: _expiryDate
      }

      const createAccount = this.$functions.httpsCallable('createFreeTrialAccount')
      const result = await createAccount(customer)
      if (result && result.data && result.data.success) {
        this.close('created-account-successfully')
        this.isCreatingAccount = false
        return
      }
      if (result && result.data && !result.data.success) {
        this.$vs.notify({
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t(`error.${result.data.code}`),
          color: 'danger'
        })
      }
      this.isCreatingAccount = false
    }
  }
}
</script>

<style>
.error-message {
  font-size: 12px; /* Adjust the font size as needed */
  color: #ff4444; /* Reddish color */
}
.fade-bottom-2x-enter-active,
.fade-bottom-2x-leave-active {
  transition: opacity 0.2s, transform 0.25s;
}
.fade-bottom-2x-enter {
  opacity: 0;
  transform: translateY(-4%);
}

.fade-bottom-2x-leave-to {
  opacity: 0;
  transform: translateY(4%);
}
.coupon-type {
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px !important;
  position: relative;
  margin: 0;
}
.vdp-datepicker__calendar header .prev:not(.disabled),
.vdp-datepicker__calendar header .next:not(.disabled),
.vdp-datepicker__calendar header .up:not(.disabled) {
  background-color: rgba(59, 134, 247, 1) !important;
  color: white;
}

.vdp-datepicker__calendar header .prev:not(.disabled):hover,
.vdp-datepicker__calendar header .next:not(.disabled):hover,
.vdp-datepicker__calendar header .up:not(.disabled):hover {
  background-color: rgba(59, 134, 247, 0.9) !important;
}
.vdp-datepicker__calendar header .next:after {
  border-left: 10px solid #fff;
}
.vdp-datepicker__calendar header .prev:after {
  border-right: 10px solid #fff;
}
.add-coupon {
  max-width: 720px;
}
.vdp-datepicker__calendar {
  width: 457px !important;
}
.justify-align-center {
  align-items: center;
  justify-content: center;
}
</style>
